<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader> Product List </CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Filter"
									placeholder="Enter Filter"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="4">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top: 27px"
									>
										<CIcon name="cil-reload" />
									</CButton>
									<CButton
										@click="add"
										id="btn"
										color="success"
										style="margin-top: 27px; margin-left: 5px"
									>
										<CIcon name="cil-plus" />
									</CButton>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height: 900px">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
							>
								<template slot="slot-actions" slot-scope="props">
									<CButton
										@click="add(props.rowData)"
										color="warning"
										size="sm"
										class="py-0"
									>
										<CIcon name="cil-pencil" />
									</CButton>
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Modal title 2"
			size="lg"
			color="info"
		>
			<CRow>
				<CCol md="12">
					<CInput label="Name" v-model="frm.name" placeholder="Nama" />
				</CCol>
			</CRow>
			<CRow>
				<CCol md="4">
					<CInput
						label="Username"
						v-model="frm.username"
						placeholder="username"
					/>
				</CCol>
				<CCol md="4">
					<CInput label="Email" v-model="frm.email" placeholder="email" />
				</CCol>
				<CCol md="4">
					<div role="group" class="form-group">
						<label for="role" class=""> Role </label>
						<select v-model="frm.role" id="role" class="form-control">
							<option
								v-for="r in roles"
								:data-key="r.code"
								:key="r.code"
								:value="r.code"
							>
								{{ r.name }}
							</option>
						</select>
					</div>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="6">
					<CInput
						label="Password"
						type="password"
						v-model="frm.password"
						placeholder="password"
					/>
				</CCol>
				<CCol md="6">
					<CInput
						label="Password Confirm"
						type="password"
						v-model="frm.passwordConfirm"
						placeholder="password confirm"
					/>
				</CCol>
			</CRow>
			<template #header>
				<h6 class="modal-title">
					{{ selectedId != null ? "Edit " + frm.name : "Add new data" }}
				</h6>
				<CButtonClose @click="modal = false" class="text-white" />
			</template>
			<template #footer>
				<CButton @click="modal = false" color="danger">CANCEL</CButton>
				<CButton @click="save" :disabled="loading" color="success"
					>SAVE</CButton
				>
			</template>
		</CModal>
	</div>
</template>

<script>
import { basuri, debounce } from "@/plugins/utils";

export default {
	data() {
		return {
			uri: basuri + "users",
			moreParams: { filter: null, t_: Date.now() },
			selectedId: null,
			loading: false,
			modal: false,
			frm: {
				name: "",
				username: "",
				email: "",
				role: "",
				password: "",
				passwordConfirm: "",
			},
			roles: [],
			fields: [
				{
					name: "name",
					title: "name",
					sortField: "name",
				},
				{
					name: "username",
					title: "Username",
					sortField: "username",
				},
				{
					name: "email",
					title: "Email",
					sortField: "email",
				},
				{
					name: "role",
					title: "role",
					sortField: "role",
				},

				{
					name: "slot-actions",
					title: "",
				},
			],
		};
	},
	watch: {
		"moreParams.filter": debounce(function (val) {
			if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
		}, 1000),
	},
	mounted() {
		this.getRole();
	},
	methods: {
		reset() {
			this.moreParams = { filter: null, t_: Date.now() };
			this.$refs.vuetable.refresh();
		},
		add(data = null) {
			if (data) {
				this.frm = JSON.parse(JSON.stringify(data));
				this.selectedId = data.id;
			} else {
				this.frm = {
					name: "",
					username: "",
					email: "",
					role: "",
					password: "",
					passwordConfirm: "",
				};
				this.selectedId = null;
			}
			this.modal = true;
		},
		async getRole() {
			try {
				const { data } = await this.$http.get("acl/role", this.frm);
				this.roles = data.data;
			} catch (error) {
				console.log(error.message);
			}
		},
		save: debounce(async function () {
			this.loading = true;

			try {
				let datar = {};
				if (this.selectedId) {
					const { data } = await this.$http.put(
						"users/" + this.selectedId,
						this.frm
					);
					datar = data;
				} else {
					const { data } = await this.$http.post("register", this.frm);
					datar = data;
				}
				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					this.$refs.vuetable.refresh();
					this.modal = false;
				} else {
					this.$swal("Fail !", datar.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loading = false;
		}, 1000),
	},
};
</script>
